import { axiosAuthorized } from "../../api/config";

export const getCategoriesCount = async () => {
  try {
    const response = await axiosAuthorized.get(`/countlabcategory`);
    return response;
  } catch (error) {
    throw error;
  }
} 


export const getCategories = async () => {
    try {
        const repsonse = await axiosAuthorized.get(`/getcategoryhealth`);
        return repsonse;
    } catch (error) {
        throw error;
    }
}
export const addCategory = async (initialState, category_icon, categoryId = null) => {
    try {
        const reqestData = {
            ...initialState,
            category_icon
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        }
        const url = categoryId ? `/category_health/update_categoryhealth` : `/category_health/add_categoryhealth`
        const repsonse = await axiosAuthorized[categoryId ? "put" : "post"](url, reqestData, requestConfig);
        return repsonse;
    } catch (error) {
        throw error;
    }
}

export const deleteCategory = async (categoryId) => {
    try {
        const repsonse = await axiosAuthorized.delete(`/category_health/delete_categoryhealth/${categoryId}`);
        return repsonse;
    } catch (error) {
        throw error;
    }
}