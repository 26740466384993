import React, { useMemo } from 'react'
import { Table } from '../../Common';

const PrescriptionsTable = ({ datas,setEditData,setOpenTracker, setAppointmentsFn }) => {
  const headings = useMemo(
    () => [ "Name", "P-Id", "Phone","Email", "Test", "Status","Tracker"],
    []
  );
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.patient_name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.phone_number}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.email}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
              {data?.test?.map((item, idx) => (
                <span key={idx}>
                  {" "}
                  {idx + 1} {". "} {item?.test_name}
                </span>
              ))}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.status}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize cursor-pointer">
              <button
                className="text-white text-sm bg-primary rounded-md py-1 px-2"
                onClick={() => {
                  setEditData(data);
                  setOpenTracker(true);
                }}
              >
                Update
              </button>
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default PrescriptionsTable