import React from 'react'
import PrescriptionsMainSection from '../../components/PrescriptionsComponents/PrescriptionsMainSection/PrescriptionMainSection';

const Prescriptions = () => {
  return (
    <div className="w-full h-fit  px-2 pt-10 sm:p-10 relative">
      <PrescriptionsMainSection/>
    </div>
  );
};

export default Prescriptions;