import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import { Dashboard, Appointments, OnlineAppointments, Tests, Login, Categories, Packages, OnlinePackagesAppointments, Prescriptions } from "./pages";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";
import { useDispatch } from "react-redux";
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";



function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const lab = JSON.parse(localStorage.getItem("lab"));
    if (lab) {
      dispatch(saveUser(lab));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Dashboard />} />
        <Route path="/appointments" element={<Appointments />} />
        <Route path="/tests-orders" element={<OnlineAppointments />} />
        <Route path="/prescriptions" element={<Prescriptions />} />
        <Route path="/packages-orders" element={<OnlinePackagesAppointments />} />
        <Route path="/tests" element={<Tests />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/packages" element={<Packages />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
