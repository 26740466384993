import React from 'react'
import HospitalSvg from './HospitalSvg';

const MessageConsulting = ({count}) => {
  return (
    <div className={`dashboard-card-container bg-[#FFA901] `}>
      <div className={`dashboard-card-icon bg-[#FFB72C] `}>
        {<HospitalSvg />}
      </div>
      <div className="dashboard-card-content">
        <span className="dashboard-card-value">{count}</span>
        <span className="dashboard-card-text">{"Total Categories"}</span>
      </div>
    </div>
  );
}

export default MessageConsulting