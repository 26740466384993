import React from 'react'
import "./DashboardCard.css"
import { AppointmentCard,MessageConsulting, TotalPatientCard } from './DashbhoardCardSvg';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { getTestsCount } from '../../../utils/testsUtils/testsUtils';
import { getCategoriesCount } from '../../../utils/categoriesUtils/categoriesUtils';
import { getAppointmentsCount } from '../../../utils/appointmentsUtils/appointmentsUtils';

const DashboardCards = () => {
  const [tests, setTests] = useState(0);
  const [appointments, setAppointments] = useState(0);
  const [categories, setCategories] = useState(0);
  const setTestsFn = useCallback(() => {
    getTestsCount()
      .then((res) =>{ setTests(res?.data?.data?.lab_test_count || 0)})
      .catch((err) => console.error(err));
  },[])
  const setAppointmentsFn = useCallback(() => {
    getAppointmentsCount()
      .then((res) =>{ setAppointments(res?.data?.data?.lab_appointment_count || 0)})
      .catch((err) => console.error(err));
  }, []);
  const setCategoriesFn = useCallback(() => {
    getCategoriesCount()
      .then((res) =>{ setCategories(res?.data?.data?.lab_category_count || 0)})
      .catch((err) => console.error(err));
  },[])
  useEffect(() => {
    setTestsFn();
    setCategoriesFn();
    setAppointmentsFn();
  }, [setTestsFn, setCategoriesFn, setAppointmentsFn]);
  return (
    <div className="dashboardcard-container">
      <AppointmentCard count={tests} />
      <TotalPatientCard count={appointments} />
      <MessageConsulting count={categories} />
    </div>
  );
}

export default DashboardCards