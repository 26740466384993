import { axiosAuthorized } from "../../api/config";

export const getPrescriptions = async (labId, mode='Today') => {
    try {
      let url = `/labprescription/get_labprescription`
        if (mode === `Completed`) {
          url = `/labprescription/get_labprescription`
        } else if (mode === `New`) {
          url = `/labprescription/get_labprescription`
        }
        const response = await axiosAuthorized.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateStatus = async (status, lab_prescription_pdf = null, appointmetnId) => {
    try {
      const requestData = lab_prescription_pdf ? { status, lab_prescription_pdf } : { status };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
      const response = await axiosAuthorized.put(`/labprescription/update_labprescription/${appointmetnId}`,requestData,requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

