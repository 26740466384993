import React from "react";
import "./WelcomeBox.css"
import { BottomIcons, SideIcons } from "./Icons";
import { useSelector } from "react-redux";

const WelcomeBox = () => {
  const user = useSelector((state) => state.userReducer.lab);
  return (
    <div className="welcomebox-container">
      <div className="welcomebox-content">
        <div className="welcome-text">Hello, {user?.name}</div>
        <div className="welcome-text-two">
          have a good earnings and hope you are good !
        </div>
      </div>
      <img
        className="welcomebox-image"
        src="/assets/images/lab_dashboard_image.png"
        alt="lab_dashboard_image"
      />
      {/* <SideIcons />
      <BottomIcons /> */}
    </div>
  );
};

export default WelcomeBox;
