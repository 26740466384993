import React from 'react'
import PatientSvg from './PatientSvg';

const TotalPatientCard = ({count}) => {
  return (
    <div className={`dashboard-card-container bg-[#FF5363]`}>
      <div className={`dashboard-card-icon bg-[#FF717F] `}>
        {<PatientSvg />}
      </div>
      <div className="dashboard-card-content">
        <span className="dashboard-card-value">{count}</span>
        <span className="dashboard-card-text">{"Total Appointments"}</span>
      </div>
    </div>
  );
}

export default TotalPatientCard