import React from 'react'
import CalendarSvg from './CalendarSvg';

const AppointmentCard = ({count}) => {
  return (
    <div className={`dashboard-card-container bg-[#7A6EFE] `}>
      <div className={`dashboard-card-icon bg-[#9188FF] `}>
        {<CalendarSvg />}
      </div>
      <div className="dashboard-card-content">
        <span className="dashboard-card-value">{count}</span>
        <span className="dashboard-card-text">{"Total Tests"}</span>
      </div>
    </div>
  );
}

export default AppointmentCard