import { axiosAuthorized } from "../../api/config";

export const getTestsCount = async () => {
    try {
      const response = await axiosAuthorized.get(`/counttotallabtest`);
        return response;
    } catch (error) {
        throw error;
    }
} 

export const getTests = async (labId) => {
    try {
        const response = await axiosAuthorized.get(`/labtest/get_labtest`);
        return response;
    } catch (error) {
        throw error;
    }
} 

export const addTest = async (initialState,image,category,faq, labId, appointmetnId = null) => {
    try {
        const requestData = {
            ...initialState,
            price: Number(initialState.price),
            discount: Number(initialState.discount),
            lab_member_id: labId,
            category,
            test_pic: image,
            faq,

        };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = appointmetnId
            ? `/lab/update_lab/${appointmetnId}`
            : `/labtest/add_labtest`;

        const response = await axiosAuthorized[appointmetnId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteTest = async (testId) => {
    try {
        const response = await axiosAuthorized.delete(`/labtest/delete_labtest/${testId}`);
        return response;
    } catch (error) {
        throw error;
    }
}